import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css'; 

const BlogContent = ({ module, pageContext }) => {
  const ref = useRef(null);
  const [userClicked, setUserClicked] = useState(false);
  const [curentHref, setCurentHref] = useState('');

  useEffect(() => {
    setCurentHref(window.location.href);
  }, []);

  const handleCopyToClipboard = () => {
    if (typeof window !== 'undefined') {
      navigator.clipboard.writeText(curentHref);
      setUserClicked(true);
    }
  };

  const defaultExpanded = module?.data
  ?.map((item, index) => (item?.defaultExpanded ? index : null))
  .filter((item) => item !== null);

  const index = pageContext.posts.findIndex((object) => {
    return object.post.id === pageContext.id;
  });

  const onTabClick = (heading) => {
    const element = document.getElementById(heading);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  const ref2 = useRef(null);
  const isInView = useInView(ref2);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = (event) => {
    const sliderContainer = event.target.parentElement.parentElement.parentElement;
    const swiper = sliderContainer.querySelector('.swiper').swiper;
    swiper.slideNext();
  };
  
  const handlePrevClickStay = (event) => {
    const sliderContainer = event.target.parentElement.parentElement.parentElement;
    const swiper = sliderContainer.querySelector('.swiper').swiper;
    swiper.slidePrev();
  };

  return (
    <section className="blog-content-single">
      <div className="blog-content-tab lg:px-70 px-30 lg:py-50 py-30">
        <div className="flex space-x-5 social-share lg:w-[69%] w-full xl:pl-70 laptop:pl-0 lg:order-first order-last">
          <div className={`blog-icon`}>
            <div className="flex justify-start items-center fade-ani">
              <span className="text-red-200 uppercase text-19 mr-20 ">
                Share:
              </span>
              <ul className="flex space-x-5">
                <li>
                  <a
                    href={`https://telegram.me/share/url?url=${curentHref}`}
                    target="_blank"
                  >
                    <img
                      className="!w-20 !h-20"
                      src="/images/telegram-icon.svg"
                      loading="lazy"
                      alt="icon-share"
                    />
                  </a>
                </li>

                <li>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${curentHref}`}
                    target="_blank"
                  >
                    <img
                      className="!w-20 !h-20"
                      src="/images/facebook-icon.svg"
                      loading="lazy"
                      alt="icon-facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://x.com/share?url=${curentHref}`}
                    target="_blank"
                  >
                    <img
                      className="!w-20 !h-20"
                      src="/images/x-icon.svg"
                      loading="lazy"
                      alt="icon-x"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${curentHref}`}
                    target="_blank"
                  >
                    <img
                      className="!w-[22px] !h-[22px]"
                      src="/images/linkedin-icon.svg"
                      loading="lazy"
                      alt="icon-linkedin"
                    />
                  </a>
                </li>
                <li>
                  {!userClicked ? (
                    <button onClick={handleCopyToClipboard}>
                      <img
                        className="!w-20 !h-20"
                        src="/images/share-icon.svg"
                        loading="lazy"
                        alt="icon-share"
                      />
                    </button>
                  ) : (
                    <div className="flex items-center gap-1">
                      <img
                        className="!w-15 !h-15"
                        src="/images/check.svg"
                        loading="eager"
                        alt="icon-share"
                      />
                      <p className="font-body font-400 text-[14px] leading-[10px] text-red-200">
                        {' '}
                        Copied!
                      </p>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-between">
          <div className="lg:w-[69%] w-full xl:pl-70 laptop:pl-0 lg:order-first order-last">
            {module.blogData &&
              module.blogData.map((item, index) => (
                <>
                  {item?.fieldGroupName ===
                    'post_Postcontent_PostContent_BlogContent_BlogData_VideoSection' && (
                    <ReactPlayer
                      className="modal-banner-video pb-30 lgscreen:py-30"
                      loop={true}
                      playing={true}
                      controls={true}
                      muted={true}
                      url={item.embeddedVideoUrl.mediaItemUrl}
                    />
                  )}

                  {item?.fieldGroupName ===
                    'post_Postcontent_PostContent_BlogContent_BlogData_TabbingSection' && (
                    <div className="content-list">
                      {item.tabbingContent &&
                        item.tabbingContent.map((item, index) => (
                          <>
                            <div
                              className="tab-item"
                              id={`${item.tabName
                                .replace(/ /g, '')
                                .toLowerCase()}`}
                            >
                              {item.blogPostContent &&
                                item.blogPostContent.map((element, index) => (
                                  <>
                                    {element.contentFormat === 'simple_cnt' && (
                                      <div className="content">
                                        {<h6>{element.heading}</h6>}
                                        <div className="ul itinerary">
                                          {element.description &&
                                            parse(element?.description)}
                                        </div>
                                        {element?.button && (
                                          <div className="btn-custom mt-30 relative z-9 fade-ani">
                                            <Link
                                              to={element.button?.url}
                                              target={element.button?.target}
                                              className="button header-btn-red cursor-pointer"
                                            >
                                              {element.button.title}
                                            </Link>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {element.contentFormat ===
                                      'img_with_content' && (
                                      <div className="grid xl:grid-cols-2 xlscreen:gap-y-5 py-30 img-content-list">
                                        {element.imagePosition == 'left' && (
                                          <div className="relative">
                                            <ImageOpt
                                              imgData={
                                                element?.image?.mediaItemUrl
                                              }
                                              imgAlt={element?.image?.altText}
                                              imgClass="object-cover"
                                            />
                                            <p className="absolute bottom-10 left-10 font-body font-400 text-[14px] leading-[10px] text-white">
                                              {element.imageOverlayText}
                                            </p>
                                          </div>
                                        )}
                                        <div className="bg-yellow flex xl:px-70 px-30 items-center heading-content">
                                          <div className="content xl:!p-0">
                                            {<h6>{element.heading}</h6>}
                                            <div className="ul pl-25">
                                              {element.description &&
                                                parse(element?.description)}
                                            </div>
                                          </div>
                                        </div>
                                        {element.imagePosition == 'right' && (
                                          <div className="relative">
                                            <ImageOpt
                                              imgData={
                                                element?.image?.mediaItemUrl
                                              }
                                              imgAlt={element?.image?.altText}
                                              imgClass="object-cover"
                                            />
                                            <p className="absolute bottom-10 left-10 font-body font-400 text-[14px] leading-[10px] text-white">
                                              {element.imageOverlayText}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {element.contentFormat ===
                                      'two_column_img' && (
                                      <>
                                        <div className="grid lg:grid-cols-2 py-30 gap-7">
                                          {element.multipleImages &&
                                            element.multipleImages.map(
                                              (image, index) => (
                                                <div
                                                  key={index}
                                                  className="relative"
                                                >
                                                  <ImageOpt
                                                    imgData={
                                                      image.image?.mediaItemUrl
                                                    }
                                                    imgAlt={
                                                      image.image?.altText
                                                    }
                                                    imgClass="object-cover"
                                                  />
                                                  <p className="absolute bottom-10 left-10 font-body font-400 text-[14px] leading-[10px] text-white">
                                                    {image.imageText}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </>
                                    )}
                                    {element.contentFormat ===
                                      'single_image' && (
                                      <>
                                        <div className="py-30">
                                          <div className="relative">
                                            <ImageOpt
                                              imgData={
                                                element?.image?.mediaItemUrl
                                              }
                                              imgAlt={element?.image?.altText}
                                              imgClass="object-cover"
                                            />
                                            <p className="absolute bottom-10 left-10 font-body font-400 text-[14px] leading-[10px] text-white">
                                              {element.imageOverlayText}
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {element.contentFormat === 'best_time' && (
                                      <>
                                        <div className="py-30">
                                          <div className="bg-yellow py-20 lg:px-100 px-50">
                                            <div className="content">
                                              {<h6>{element.heading}</h6>}
                                              <div className="">
                                                {element.description &&
                                                  parse(element?.description)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {element.contentFormat === 'qoute' && (
                                      <>
                                        <div className="py-30">
                                          <h6 className="font-secondary font-500 text-30 leading-[39px] text-black-500 normal-case italic">
                                            {element.heading}
                                          </h6>
                                          <p className="font-body font-[350] text-14 leading-[19px] uppercase text-black-500 mt-20">
                                            {' '}
                                            {element.description &&
                                              parse(element?.description)}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {element.contentFormat === 'sm_big_img' && (
                                      <>
                                        <div className="grid lg:grid-cols-3 py-30 gap-6">
                                          <div className="relative lg:col-span-2 sm_big_img">
                                            <ImageOpt
                                              imgData={
                                                element?.firstImage
                                                  ?.mediaItemUrl
                                              }
                                              imgAlt={
                                                element?.firstImage?.altText
                                              }
                                              imgClass="object-cover"
                                            />
                                            <p className="absolute bottom-10 left-10 font-body font-400 text-[14px] leading-[10px] text-white">
                                              {element.imageOverlayText}
                                            </p>
                                          </div>
                                          <div className="relative">
                                            <ImageOpt
                                              imgData={
                                                element?.secondImage
                                                  ?.mediaItemUrl
                                              }
                                              imgAlt={
                                                element?.secondImage?.altText
                                              }
                                              imgClass="object-cover"
                                            />
                                            <p className="absolute bottom-10 left-10 font-body font-400 text-[14px] leading-[10px] text-white">
                                              {element.imageOverlayText}
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {element.contentFormat ===
                                      'important_notices' && (
                                      <>
                                        <div className="blog-content-single__reminders border-2 border-red-200 py-15 px-30">
                                          <div className="content">
                                            <h6>
                                              {element.importantNoticesHeading}
                                            </h6>
                                            {element.importantNotices &&
                                              parse(element?.importantNotices)}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {element.contentFormat ===
                                      'gallery_slider' && (
                                      <div
                                        className={`grid-slider lg:py-60 py-30`}
                                      >
                                        <div
                                          className="relative fade-ani"
                                        >
                                          <Swiper
                                            id={`swiper-${uuidv4()}`}
                                            loop={true}
                                            slidesPerView={2.5}
                                            allowTouchMove={true}
                                            centeredSlides={true}
                                            grabCursor={true}
                                            modules={[
                                              Pagination,
                                              Navigation,
                                            ]}
                                            breakpoints={{
                                              100: {
                                                slidesPerView: 1.3,
                                                spaceBetween: 20,
                                              },
                                              768: {
                                                slidesPerView: 2.5,
                                                spaceBetween: 20,
                                              },
                                              1024: {
                                                slidesPerView: 2.5,
                                                spaceBetween: 0,
                                              },
                                            }}
                                          >
                                            {element?.gallery?.length > 0 &&
                                              element.gallery.map(
                                                (image, index) => (
                                                  <SwiperSlide key={uuidv4()}>
                                                    <div className="grid-slider-inner">
                                                      <ImageOpt
                                                        imgData={
                                                          image?.mediaItemUrl
                                                        }
                                                        imgAlt={image?.altText}
                                                        imgClass="object-cover object-center"
                                                      />
                                                    </div>
                                                  </SwiperSlide>
                                                )
                                              )}
                                          </Swiper>
                                          <div className="swiper-arrow swiper-arrow-red">
                                            <a
                                              className="button-prev cursor-pointer absolute top-0 left-[18vw] mdscreen:left-[20px] translate-y-minus_50 lgscreen:top-[28%] mdscreen:top-[21%] z-[!999]"
                                              onClick={handlePrevClickStay}
                                            >
                                              <img
                                                src="/images/white-arrow.svg"
                                                loading="lazy"
                                                className="scale-x-[-1] !z-1"
                                                alt=""
                                              />
                                            </a>
                                            <a
                                              className="button-next cursor-pointer absolute top-[30%] lgscreen:top-[28%] mdscreen:top-[21%] right-[18vw] mdscreen:right-[20px] translate-y-minus_50 z-[!999]"
                                              onClick={handleNextClickStay}
                                            >
                                              <img
                                                src="/images/white-arrow.svg"
                                                loading="lazy"
                                                className="!z-1"  
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {element.contentFormat === 'divider' && (
                                      <hr className="border-b-1 border-[#DBDBDB] my-15"></hr>
                                    )}
                                    {element.contentFormat ===
                                      'two_column_content' && (
                                      <div className="two-col-content">
                                        <div className="content">
                                          <h6 className="">
                                            {element.twoColumnContentHeading}
                                          </h6>
                                          <p className="">
                                            {
                                              element.twoColumnContentDescription
                                            }
                                          </p>
                                        </div>
                                        <div className="two-col-content__columns grid grid-cols-2 gap-7">
                                          <div className="two-col-content__col-one">
                                            <div className="content">
                                              {parse(element.columnOne)}
                                            </div>
                                          </div>
                                          <div className="two-col-content__col-two">
                                            <div className="content">
                                              {parse(element.columnTwo)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                            </div>
                          </>
                        ))}
                    </div>
                  )}

                  {item?.fieldGroupName ===
                    'post_Postcontent_PostContent_BlogContent_BlogData_Faq' && (
                      <section
                        className={`faq lg:py-120 py-30${
                          item?.extraClass ? ' ' + item.extraClass : ''
                        }${
                          item?.backgroundStyle === 'background with pattern'
                            ? ' bg-pattern'
                            : ''
                        }`}
                      >
                        <div className="container-fluid-md fade-ani">
                          <div className="text-left">
                            {item?.heading && (
                              <div
                                className="title-black m-auto lgscreen:w-full"
                              >
                                <h3>{item.heading}</h3>
                              </div>
                            )}
                          </div>
                          <div
                          >
                            <div
                              className="accordian-section smscreen2:px-0"
                            >
                              <Accordion allowZeroExpanded={true} preExpanded={defaultExpanded}>
                                {item?.data &&
                                  item?.data?.length > 0 &&
                                  item.data.map((item, index) => (
                                    <AccordionItem key={index} uuid={index}>
                                      <AccordionItemHeading className="accordion">
                                        <AccordionItemButton>{item.question}</AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel className="fade-ani accordian-content content global-list pt-20">
                                        {item?.answer && parse(item.answer)}
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  ))}
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      </section>
                  )}
                </>
              ))}
            <div className="flex space-x-5 social-share items-center">
              <section className={`blog-icon lg:pt-60 pt-30`}>
                <div className="flex justify-center items-center fade-ani">
                  <span className="text-red-200 uppercase text-19 mr-20 ">
                    Share:
                  </span>
                  <ul className="flex space-x-5">
                    <li>
                      <a
                        href={`https://telegram.me/share/url?url=${curentHref}`}
                        target="_blank"
                      >
                        <img
                          className="!w-20 !h-20"
                          src="/images/telegram-icon.svg"
                          loading="lazy"
                          alt="icon-share"
                        />
                      </a>
                    </li>

                    <li>
                      <a
                        href={`https://www.facebook.com/sharer.php?u=${curentHref}`}
                        target="_blank"
                      >
                        <img
                          className="!w-20 !h-20"
                          src="/images/facebook-icon.svg"
                          loading="lazy"
                          alt="icon-facebook"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://x.com/share?url=${curentHref}`}
                        target="_blank"
                      >
                        <img
                          className="!w-20 !h-20"
                          src="/images/x-icon.svg"
                          loading="lazy"
                          alt="icon-x"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${curentHref}`}
                        target="_blank"
                      >
                        <img
                          className="!w-[22px] !h-[22px]"
                          src="/images/linkedin-icon.svg"
                          loading="lazy"
                          alt="icon-x"
                        />
                      </a>
                    </li>
                    <li>
                      {!userClicked ? (
                        <button onClick={handleCopyToClipboard}>
                          <img
                            className="!w-20 !h-20"
                            src="/images/share-icon.svg"
                            loading="lazy"
                            alt="icon-share"
                          />
                        </button>
                      ) : (
                        <div className="flex items-center gap-1">
                          <img
                            className="!w-15 !h-15"
                            src="/images/check.svg"
                            loading="eager"
                            alt="icon-share"
                          />
                          <p className="font-body font-400 text-[14px] leading-[10px] text-red-200">
                            {' '}
                            Copied!
                          </p>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <hr className="border-b-1 border-[#DBDBDB] my-15" />
            <div className="flex justify-between pb-15">
              <div>
                {index != 0 && (
                  <Link
                    to={pageContext?.posts[index - 1]?.post.uri}
                    className="font-secondary italic text-16 leading-[21px] text-black-500 font-[500]"
                  >
                    Previous Entry
                  </Link>
                )}
              </div>
              <div>
                {(pageContext?.posts.length != index + 1) != 0 && (
                  <Link
                    to={pageContext?.posts[index + 1]?.post.uri}
                    className="font-secondary italic text-16 leading-[21px] text-black-500 font-[500]"
                  >
                    Next Entry
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="lg:w-1/4 w-full lg:order-last order-first pt-20">
            <div className="bg-yellow lg:px-50 px-30 flex items-center sticky top-100">
              <div className="content">
                <h6 className="!mb-15">In this article</h6>
                <ul className="">
                  {module.blogData &&
                    module.blogData.map((item, index) => (
                      <>
                        {item?.fieldGroupName ===
                          'post_Postcontent_PostContent_BlogContent_BlogData_TabbingSection' &&
                          item?.tabbingContent.map((item) => (
                            <li
                              className="cursor-pointer"
                              onClick={() =>
                                onTabClick(
                                  item.tabName.replace(/ /g, '').toLowerCase()
                                )
                              }
                            >
                              {item.tabName}{' '}
                            </li>
                          ))}
                      </>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogContent;
export const BlogContentFragment = graphql`
  fragment BlogContentFragment on WpPost_Postcontent_PostContent_BlogContent {
    id
    extraClass
    hideSection
    blogData {
      ... on WpPost_Postcontent_PostContent_BlogContent_BlogData_VideoSection {
        fieldGroupName
        embeddedVideoUrl {
          altText
          title
          mediaItemUrl
        }
      }
      ... on WpPost_Postcontent_PostContent_BlogContent_BlogData_Faq {
        fieldGroupName
        hideSection
        extraClass
        heading
        backgroundStyle
        data {
          answer
          question
          defaultExpanded
        }
      }
      ... on WpPost_Postcontent_PostContent_BlogContent_BlogData_TabbingSection {
        fieldGroupName
        tabbingContent {
          tabName
          blogPostContent {
            button {
              target
              title
              url
            }
            firstImageText
            secondImageText
            contentFormat
            description
            fieldGroupName
            importantNoticesHeading
            importantNotices
            twoColumnContentHeading
            twoColumnContentDescription
            columnOne
            columnTwo
            gallery {
              altText
              mediaItemUrl
              title
            }
            firstImage {
              altText
              mediaItemUrl
              title
            }
            image {
              altText
              title
              mediaItemUrl
            }
            multipleImages {
              imageText
              image {
                altText
                title
                uri
                mediaItemUrl
              }
            }
            secondImage {
              altText
              mediaItemUrl
              title
              uri
            }
            heading
            headingStyle
            imageOverlayText
            imagePosition
          }
        }
      }
    }
  }
`;